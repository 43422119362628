import React from 'react'
import { useEffect } from 'react';
import { Grid } from "@mui/material";
import SideNote from "../components/SideNote";
import SimpleList from "../components/SimpleList";
import '../App.css';

// Data
import { disagreementReactionExampleSideNote, allPartiesCrustySideNote, jdVanceResearchSideNote, muskCensorshipSideNote, acceptingDisagreementSideNote, antiTransCrustySideNote, transRightsSideNote, takeiTweetSideNote, moreTrumpCrustyExamplesSideNote, laptopMisinformationSideNote, respectingChoiceSideNote, peopleIAgreeWithSideNote, crustyExamples, trumpExamples } from '../data/CrustyData';

// Images
import otisImage from '../images/Crusty/otis.jpg';
import takeiTweet from '../images/Crusty/crusty-tweet.png';
import positiveTweet from '../images/Crusty/positive-tweet.png';
import nraLobby from '../images/Crusty/nra-lobby.png';

export const Crusty = ({}) => {
  useEffect(() => {
    document.title = 'CrustFirst.com';
  }, []);
  return (
    <Grid className="centered-content">
      <div className="container">
        <p className="essay-title">
          Woke vs. Crusty
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">Being “woke” used to mean caring about important societal problems, and under that definition you could apply it to any issue. Just as it would be absurd to say black people and women haven’t been marginalized in the U.S., it would also be absurd to say Republicans haven’t been marginalized by many elite institutions or that men don’t face their own <a href="https://pubmed.ncbi.nlm.nih.gov/39154588/" target="_blank">systemic challenges</a>.</span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">The definition has evolved haphazardly, and everyone seems to ignore that it now refers to two different ideas: you can be woke if you have left-wing political views (supporting trans rights, for example), but you can also be woke if you’re intolerant to disagreement (supporting cancel culture, for example). Those two meanings need to be separated into different words. Just because you support trans rights doesn’t mean you support people being canceled for opposing beliefs, but right now people would refer to both cases as “woke”. The first meaning is similar enough to the original usage that it can still fall under “woke”, and the word for the second meaning should be “crusty”. Based on how people use “woke”, we can define “crusty” as being <u>closed-minded</u> about seeing the world in a different way, and <u>negative</u> to people who see the world in a different way.</span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">Since “crusty” refers only to intolerance to disagreement and doesn’t necessarily relate to left-wing ideology, you can apply it to any political position. Just as it’s crusty to believe all men are evil, it’s also crusty to believe all liberals hate conservatives. Democrats have plenty of things they’re crusty about, and so do Republicans <SideNote note={allPartiesCrustySideNote} isCaption={false} />, because having a closed-minded or negative reaction to disagreement is a normal part of how most people’s brains work. Acting in a crusty way includes:</span>
          <SimpleList listItems={crustyExamples} />
          <span className="essay-text">What’s clear from that list is how common it is to be crusty from all directions of any political issue. Everyone thinks it’s only the other side that’s being crusty.</span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Being crusty isn’t about any position you take or how extreme the position is, it’s how you handle disagreement, and as a result you can tell when someone is being crusty regardless of what they’re advocating for or what era they’re in. Gay marriage was unspeakably extreme for most of the population until relatively recently and would have been labeled “woke” if modern social media existed in previous decades, but now opposing gay marriage is the fringe position. Being against gay marriage didn’t (and still doesn’t) automatically make someone crusty, as long as they’re receptive to disagreement. Conversely, it’s possible to be crusty in support of gay marriage if you’re hateful to people who disagree with you. I suspect and hope transgender rights will follow a similar path as gay rights. Supporting trans people has been cast as a “woke” position, and while I don’t agree with people fighting against trans rights, I do agree that some people advocating for trans rights are doing it in a crusty way. <SideNote note={antiTransCrustySideNote} isCaption={false} />
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            It can feel like people who fight for injustice will never change and we’re supposed to be crusty toward them, but the evidence overwhelmingly shows that we shouldn’t assume anyone is a lost cause. If Daryl Davis can convince literal Klansmen to <a target="_blank" rel="noopener noreferrer" href="https://www.npr.org/2017/08/20/544861933/how-one-man-convinced-200-ku-klux-klan-members-to-give-up-their-robes">leave the KKK</a>, then we have to assume it’s possible to <a target="_blank" href="https://digital.libraries.psu.edu/digital/collection/rabin/id/2350/">win the understanding</a> of most people. Being crusty to them, on the other hand, guarantees that will never happen. You can and should hold strong positions about what you think is right, and you can and should work fiercely to advance what you think is right and counteract what you think is wrong. You don’t have to stay silent or surrender to anything – you should say or do something whenever you want to – but not all responses have the same effect. I’m not saying heartbreak or frustration or anger aren’t valid reactions, or even that those shouldn’t be part of your response, I’m saying that channeling those emotions to put other people down inevitably works against what you want to see happen.
          </span>
        </p>
        <div className="image-container">
          <img src={positiveTweet} className="essay-image essay-image-large" />
          <p className="image-caption">
            <span>
              The first message is awful, and then the rest of the conversation is so incredible that it should be required reading in every elementary school
            </span>
          </p>
        </div>
        <p className="essay-paragraph">
          <span className="essay-text">
            That being said, we’ve all been at least a little crusty at some point, because it’s healthy to be too emotionally attached in certain situations. You can show crustiness in some areas and be positive and productive in other areas of your life – it doesn’t have to be an all-or-nothing part of who you are. We should want people to be overprotective (within limits) about their family, friends, and pets.
          </span>
        </p>
        <div className="image-container">
          <img src={otisImage} className="essay-image" />  
          <p className="image-caption">
            I am staunchly pro-Otis
          </p>
        </div>
        <p className="essay-paragraph">
          <span className="essay-text">Based on the times I’ve caught myself being crusty, I’m now convinced that people are <u>unable</u> to see an uncomfortable truth, instead of being <u>unwilling</u> to see it. “Intellectual dishonesty” implies intent; these are intellectual blindspots. Noticing that it’s normal to have intellectual blindspots has helped me make sense of how people act about the most controversial topics.</span>
          <ul className="arrow-list">
              <li className="arrow-list-item">
                <span className="arrow-list-arrow essay-text">➔</span> 
                <span className="essay-text">For freedom of speech, intellectual blindness might come from tribalism. When some of Hunter Biden’s emails were leaked in 2020, left-leaning media outlets were quick to <a href="https://nypost.com/2020/12/10/how-media-covered-up-the-hunter-biden-story-until-after-the-election/" target="_blank">dismiss it</a> as a hoax. Twitter blocked the story and <a href="https://variety.com/2020/digital/news/twitter-blocking-nypost-china-hunter-biden-account-locked-1234808416/" target="_blank">froze</a> the original poster’s account. Two weeks later, Twitter realized their mistake and loosened the policy to allow the story to spread. When research from the Trump campaign about JD Vance was leaked in 2024{/*  <SideNote note={jdVanceResearchSideNote} isCaption={false} /> */}, the new Twitter ownership blocked the story, and took censorship even further than the previous ownership by <a href="https://www.theverge.com/2024/9/26/24255298/elon-musk-x-blocks-jd-vance-dossier" target="_blank">suspending</a> the original poster’s account. <SideNote note={muskCensorshipSideNote} isCaption={false} />Two weeks later, Twitter realized their mistake and reinstated the account. If the handling of the Hunter Biden story was wrong, how is the handling of the JD Vance story ok? People usually aren’t intellectually dishonest on purpose, they just hold certain values so closely that they can’t see there might be another way to look at something. It’s not that Democrats or Republicans are the party of censorship, it’s that all of us have a hard time accepting information that goes against our goals.</span> 
              </li>
              <li className="arrow-list-item">
                <span className="arrow-list-arrow essay-text">➔</span> 
                <span className="essay-text">For abortion rights, intellectual blindspots might come from a strong sense of justice. I’m pro-choice, but I’ve found it odd that other pro-choice people don’t appreciate how reasonable the pro-life argument is. Thinking of a fetus as a human being <i>seems reasonable to me</i>. We don’t know exactly when life begins – it could be when an egg is fertilized, or when a baby is delivered, or anywhere in between. If you never accept that perspective, the natural response is that all pro-life people hate women and only want to control them. Meeting 99.9% of pro-lifers will show you how ridiculous that is. Everyone I know personally who’s pro-life is deeply caring and feminist, and have turned to their religion on the question of when life begins. However, to briefly advocate for my own side, the pro-choice argument is that a fetus is part of a woman’s body, <i>and I don’t see how you can find that unreasonable</i>.</span> 
              </li>
              <li className="arrow-list-item">
                <span className="arrow-list-arrow essay-text">➔</span> 
                <span className="essay-text">For the Israel-Palestine war, intellectual blindspots might come from anguish.</span> 
              </li>
              <li className="arrow-list-item arrow-list-subitem">
                <span className="arrow-list-arrow essay-text">◆</span> 
                <span className="essay-text">I couldn’t believe it when people <a href="https://www.adl.org/resources/article/anti-israel-activists-celebrate-hamas-attacks-have-killed-hundreds-israelis" target="_blank">voiced support</a> for Hamas’s October 7, 2023 terrorist attack on Israel, which included widespread murder, rape, and kidnapping, <i>even before Israel retaliated in any way</i>. It wasn’t a handful of fringe-of-the-fringe cranks, it was hundreds of regular people who are otherwise smart and compassionate, including college students across the country. Even though the opinions they expressed were horrible, I don’t think they’re horrible people and instead think they’ve been so justifiably hurt by Israel’s apartheid of Palestinians that Hamas’s barbaric terrorism doesn’t register.</span> 
              </li>
              <li className="arrow-list-item arrow-list-subitem">
                <span className="arrow-list-arrow essay-text">◆</span> 
                <span className="essay-text">I still can’t believe it when otherwise smart, compassionate people show concern only for Israelis, not caring about the <a href="https://www.atlanticcouncil.org/blogs/menasource/coi-war-crimes-hamas-israel-october-7-gaza-hostages/" target="_blank">abundance of evidence</a> that the Israeli army is carrying out widespread murder of Palestinians, including children, far beyond situations where Hamas uses Palestinians as human shields. The IDF is guilty of war crimes at the very least, and a strong argument can be made for genocide. Even though many supporters of Israel don’t speak up against the IDF’s actions, I don’t think they’re horrible people and instead think they’ve been so justifiably hurt by Hamas’s barbaric terrorism that the IDF’s own barbarism doesn’t register. I support Israel, I support its right to exist, but not only are the current actions horrific on their own, it’s looking like they’re also self-destructive for Israel.</span> 
              </li>
          </ul>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">When you don’t take into account that we’re all susceptible to intellectual blindness, it can seem like someone who disagrees with you could only have the most evil reasons for acting in a certain way. We have to leave room for the possibility that there’s something they have a blindspot about, and what seems evil to you is actually reasonable and benevolent to them. You become crusty when you reject the possibility that someone disagreeing with you might have good, moral reasons for their position <SideNote note={acceptingDisagreementSideNote} isCaption={false} />. None of us are immune from having blindspots or being crusty. The most important thing to keep in mind about crustiness is that <b>being crusty doesn’t make you a bad person</b>. I’m crusty about certain topics, everyone I know is crusty about certain topics, probably everyone in the world is crusty to varying degrees about certain topics. How could we not be, when we face such difficult problems that have such huge impacts on our lives?</span>
        </p>
        <div className="image-container">
          <img src={takeiTweet} className="essay-image" />
          <p className="image-caption">
            <span>
              It would’ve been so easy to leave off that last line. How does anyone think this is a helpful way to communicate? <SideNote note={takeiTweetSideNote} isCaption={true} />
            </span>
          </p>
        </div>
        <p className="essay-paragraph">
          <span className="essay-text">The challenge is limiting our crustiness as much as possible. Our individual crusty interactions collectively cause even greater harm at the national level. Liberal crustiness is what makes it believable that Democrats are the party of Radical Communists Who Want To End America. It’s natural to mix a party’s candidates with that party’s voters, and when the most visible voters are extremists, I don’t blame people for believing Republican propaganda about what a Harris presidency would be like. Harris won’t “cancel” the First Amendment, the Second Amendment, religion, capitalism, or democracy, like Trump and other Republican politicians are claiming. Harris acts independently from the crustiest part of the left-wing (most of the time).</span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">With Trump on the other hand, being crusty is core to his personality. He has created himself in the image of the crustiest part of the right-wing: the headline of his website says, “<a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20241106061013/https://www.donaldjtrump.com/">They’re not after me, they’re after you</a>” and he calls liberals “<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=9DauQjmpqZM">the enemy from within</a>”. Trump’s crustiness makes him act on his nice-sounding <a target="_blank" rel="noopener noreferrer" href="https://www.donaldjtrump.com/issues">policy ideas</a> in ways that make people’s lives worse, from preventing Democrats from securing the border, to tariffs that will make everything more expensive again just as inflation is <a target="_blank" rel="noopener noreferrer" href="https://www.bls.gov/charts/consumer-price-index/consumer-price-index-by-category-line-chart.htm">back under control</a>, to escalating conflict in the Middle East. That’s not far-left fearmongering, those are things he’s already done.</span>
          <SimpleList listItems={trumpExamples}/>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">You can’t separate someone’s character from their policies, because their character determines how the policies are put into practice. Trump’s crusty character runs through almost everything he does. <SideNote note={moreTrumpCrustyExamplesSideNote} isCaption={false} />How would it be possible for someone like that to make good decisions about the most serious challenges facing the country? And now that the reasonable people from his presidency have <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/List_of_Trump_administration_dismissals_and_resignations">left his administration</a>, he’s even more extreme, surrounding himself with people even more <a target="_blank" rel="noopener noreferrer" href="https://apnews.com/article/laura-loomer-trump-911-conspiracy-theories-18198b8ea2ce567467acfd6bf7f19f1e">disconnected from reality</a>. That includes replacing his previous running mate who <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/2x0jrosGBYY?si=zWkhsYt2jFkMG736&amp;t=26">stood up to him</a> trying to <a target="_blank" rel="noopener noreferrer" href="https://www.factcheck.org/2023/08/what-trump-asked-of-pence/">overturn</a> the <a target="_blank" rel="noopener noreferrer" href="https://www.foxnews.com/politics/trump-insists-he-won-georgia-in-call-with-state-election-official-urges-him-to-find-votes-report">election</a>, with someone who refuses to say that Trump lost the election. <SideNote note={laptopMisinformationSideNote} isCaption={false} />I have plenty of frustrations about Harris, and she is crusty on some <a target="_blank" rel="noopener noreferrer" href="https://kamalaharris.com/issues/">policies</a>, but with her we’d have a president with at least a basic ability to navigate our current problems.</span>
        </p>
        {/* <div className="image-container">
          <img src={nraLobby} className="essay-image" />  
          <p className="image-caption">
            The NRA censoring the first part of the Second Amendment in their museum <a target="_blank" rel="noopener noreferrer" href="https://www.washingtonpost.com/news/retropolis/wp/2017/10/05/the-forgotten-nra-leader-who-despised-the-promiscuous-toting-of-guns/">lobby</a>
          </p>
        </div> */}
        {/* 
        <p className="essay-paragraph">
          <span className="essay-text">You can see why so many Americans can’t imagine how it’s possible to even consider Trump as president, and why <a target="_blank" rel="noopener noreferrer" href="https://www.fec.gov/resources/cms-content/documents/federalelections2020.pdf">80 million people</a> voted for Biden instead of Trump in 2020, when the impact of Trump’s presidency was fresh in our minds.</span>
        </p> */}
        <p className="essay-paragraph">
          <span className="essay-text">
            I wish the media hadn’t become biased against Trump, because all they had to do was show his speeches and describe his actions without any spin. After fact-checking myself I had to delete some of the points I had originally written, because I was wrong about certain things I believed about him. And I hope it’s clear that my criticism of Trump is different from demonization. Remember, we’re probably all crusty about some political belief, it’s just a matter of degree. Trump’s a human being, with flaws and strengths just like anyone else. I’ll readily acknowledge there were positive things he did, like funding Operation Warp Speed for the covid vaccine. And as Biden and Harris have said, the assassination attempts against him are despicable.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Demonizing Trump’s supporters is just as wrong. For friends who want a president who won’t legalize abortion, I support them voting for Trump. I wish they didn’t want to ban abortion, and I wish they wouldn’t vote for Trump, but I recognize the valid reasons for their views. I respect and support people voting for Trump for any policies that are important to them. <SideNote note={respectingChoiceSideNote} isCaption={false} />That even goes for people who I don’t think have valid reasons for their position – if people think he’ll protect the country from the lizards secretly running the government, I support them voting for him too. <SideNote note={peopleIAgreeWithSideNote} isCaption={false} />
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Demonizing any group is wrong. The crusty tactic that bothers me most is when leaders turn Americans against each other, inflaming crustiness, manipulating us so they can get power. It creates a downward spiral of negativity, which weakens the country and prevents positive change.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            It’s common to view presidential elections as “deciding who we want to be as a country”. That feels like too big of a goal, because it’s out of our individual control. What we do have control over is who each of us wants to be. Politics and norms change. What we can always control is our mindset – how we respond to information and other people in the midst of a changing world. I want to be someone who’s a little more open-minded, a little more aware that people can have good reasons for disagreeing with me, a little more patient with people who I know are wrong, all while working to be effective in the change I know is right. It feels like we’ve been pulling apart from each other in this country, and I hope I can be a part of reversing that to put us back together. Whichever path you think will put us in the direction of making things better – whether that’s voting for Harris, voting for Trump, voting for someone else, or not voting at all – you owe it to yourself, not anyone else, to follow that path. Only you know what’s right for you and your loved ones. That means actually making a decision, and actually taking action. Decide for yourself and do what’s right for you.
          </span>
        </p>
        <p className="essay-text" style={{marginTop:'70px'}}>
          <a href="/">(Click here to view the one-minute version of this essay)</a>
        </p>
      </div>
    </Grid>
  )
}
